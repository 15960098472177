.middle-nav-bar {
    left:0;
    right:0;
    /*border:1px solid blue;*/
    background-color: #fff;
    position: absolute;
    /*width:100vw;*/
}

.footer {
    height:100px;
    width:100%;
}
