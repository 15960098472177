.mobile-panel-container{
    position:fixed;
    top:0;
    width:0;
    right:0;
    bottom:0;
    transition: width .2s ease-in;
    background-color: #eee;
    box-sizing: border-box;
}

.mobile-panel-container::before{
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    z-index: 5;
    opacity: .8;
    position: absolute;
    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.65)),to(rgba(0,0,0,0)));
    background: linear-gradient(to right,rgba(0,0,0,.65) 0,rgba(0,0,0,0) 100%);
}

.increase-mp-width {
    width:276px;
}

.mobile-panel-nav-container {
    width:276px;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    overflow-x:hidden;
    overflow-y: auto;
}

.mobile-panel-nav-list {
    /*width:276px;*/
    padding:0;
    margin:0;
    list-style-type: none;
    overflow: auto;
}

.mobile-panel-nav-list-item-link-close, .mobile-panel-nav-list-item-link-open, .mobile-panel-nav-list-item-link-plain {
    padding:10px 20px 9px;
    text-decoration: none;
    font-size:14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color:#444444;
    border-bottom: 1px solid #aaa;
    line-height: 20px;
    display:block;
    position: relative;
}

.mobile-panel-nav-list-item-link-close:after{
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #444;
    border-bottom: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
}

.mobile-panel-nav-list-item-link-open:after{
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 0;
    border-bottom: 6px solid #444;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
}

/**dropdown list styles**/
.mobile-panel-dropdown-nav-list{
    /*width:276px;*/
    padding:0;
    margin:0;
    list-style-type: none;
    background-color: #fff;
}

.mobile-panel-dropdown-nav-list-item-link-close, .mobile-panel-dropdown-nav-list-item-link-open, .mobile-panel-dropdown-nav-list-item-link-plain {
    padding:10px 20px 9px;
    text-decoration: none;
    font-size:14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color:#444444;
    border-bottom: 1px solid #aaa;
    line-height: 20px;
    display:block;
    position: relative;
}

.mobile-panel-dropdown-nav-list-item-link-close::after {
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #aaa;
    border-bottom: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
}

.mobile-panel-dropdown-nav-list-item-link-open:after{
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 0;
    border-bottom: 6px solid #aaa;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
}

/**sub-dropdown styles**/
.mobile-panel-sub-dropdown-nav-list{
    width:276px;
    padding:0;
    margin:0;
    list-style-type: none;
    background-color: #fff;
}

.mobile-panel-sub-dropdown-nav-list-item {
    padding-left:15px;
    border-left:4px solid black;
}

.mobile-panel-sub-dropdown-nav-list-item-link {
    padding:10px 20px 9px;
    text-decoration: none;
    font-size:14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color:#444444;
    border-bottom: 1px dotted #aaa;
    line-height: 20px;
    display:block;
}

.hide-mobile-dropdown-list, .hide-mobile-sub-dropdown-list {
    display:none;
}
