.top-nav-container {
    /*border:1px solid black;*/
    display: none;
    margin-top:-17px;
    padding-left:34px;
    box-sizing: border-box;
    padding:0 15px 0 0;    
}

.top-nav-list {
    padding:0;
    list-style-type: none;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    font-size: 14px;
}

.top-nav-list-item {
    padding:7px 20px;
    height:30px;
    position:relative;
    box-sizing: border-box;
}


.top-nav-list-item:hover {
    background-color: #ffffff;
    cursor: pointer;
}

/*
.top-nav-list-item:hover .dropdown-menu {
    display:block;
}
*/

.dropdown-menu {
    position:absolute;
    left:-1px;
    top:100%;
    /*top:30px;*/
    padding:0;
    margin:0;
    list-style-type: none;
    box-sizing: border-box;
    width:225px;
    background-color: #ffffff;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    border-left: 1px solid #cce7f3;
    border-right: 1px solid #cce7f3;
    display:none;
    z-index:5;
}

.show-dd-menu {
    display:block;
}

.dropdown-sub-menu {
    position: absolute;
    display:flex;
    padding:0;
    margin:0;
    top:0;
    left:100%;
    list-style-type: none;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    border-left: 1px solid #cce7f3;
    border-right: 1px solid #cce7f3;
    display:none;
}

.show-sub-dd-menu{
    display:flex;
}

.dropdown-sub-menu-adjust {
    position: absolute;
    padding:0;
    margin:0;
    top:0;
    bottom:0;
    left:100%;
    width:225px;
    list-style-type: none;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    border-left: 1px solid #cce7f3;
    border-right: 1px solid #cce7f3;
    display:none;
}

.show-sub-dd-menu-adjust{
    display:block;
}

.dropdown-menu-item {
    /*position: relative;*/
    /*padding:2px 10px 2px 20px;*/
    /*padding:0 10px 0 20px;*/
    /*min-height:36px;*/
    /*border:1px solid black;*/
    display:flex;
    align-items: center;
    box-sizing: border-box;
    /*cursor: pointer;*/
}

.img-adjust {
    position:relative;
}

/*
.dropdown-menu-item:hover {
    background-color: lightblue;
}
*/

/*show submenu when dropdown item selected
.dropdown-menu-item:hover .dropdown-sub-menu {
    display:flex;
    box-sizing: border-box;
}
*/


.dropdown-menu-item:hover .dropdown-sub-menu-adjust {
    display:block;
}


.dropdown-sub-menu-item {
    /*position:relative;*/
    /*border:1px solid black;*/
    min-width:200px;
    box-sizing: border-box;
    /*padding:0 10px 0 20px;*/
    /*display:flex;
    justify-content: center;
    align-items: center;*/
    padding:20px 10px;
}

.dropdown-sub-menu-item:nth-child(1){
    border-right:1px solid #eee;
}

/*original version for sub menu without javascript
.dropdown-sub-menu-item:hover {
    background-color: #cce7f3;
}
*/

.dropdown-sub-menu-item .sub-menu-item-caption {
    font-size:18px;
    margin:8px 0 5px;
}

.dropdown-sub-menu-item span {
    display:block;
}

/*
.sub-menu-item-img-container {
    text-align:center;
}
*/

.sub-menu-item-link {
    text-align: center;
    text-decoration: none;
    display:block;
    /*padding:20px 10px;*/
    min-height:34px;
    /*border:1px solid red;*/
}

.dropdown-sub-menu-item-adjust {
    /*position:relative;*/
    /*border:1px solid black;*/
    min-width:200px;
    box-sizing: border-box;
    /*min-height:35px;*/
    /*padding:10px 10px 10px 20px;*/

}

.sub-menu-item-adjust-link {
    flex-basis:100%;
    display:flex;
    align-items: center;
    text-decoration: none;
    color:#444;
    padding:6px 10px 6px 20px;
    min-height:34px;
    box-sizing: border-box;
}

.dropdown-sub-menu-item-adjust-name {
    text-align: left;
}

/*
.dropdown-sub-menu-item-adjust:hover {
    background-color: lightblue;
}
*/

/*
.sub-menu-item-adjust-link:hover {
    background-color: #cce7f3;
}
*/

.adjust-left-border {
    border-left:1px solid rgb(241, 240, 240);
}

.dropdown-menu-item-link {
    /*border:1px solid red;*/
    flex-basis:100%;
    display:flex;
    align-items: center;
    text-decoration: none;
    color:#444;
    padding:6px 10px 6px 20px;
    box-sizing: border-box;
    min-height: 34px;
}

/*
.dropdown-menu-item-link:hover {
    background-color:#cce7f3;
}
*/

.show-dd-menu-item-bg-color {
    background-color:#cce7f3;
}

.dropdown-menu-item-name {
    flex-grow: 1;
    text-align: left;
    font-size: 14px;
}

.remove-bg-color {
    background-color: transparent!important;
}

.right-angle {
    font-size:26px;
}

/**overlay styles**/
.overlay {
    position: absolute;
    left:0;
    height:100vh;
    width:100%;
    background-color: black;
    opacity:.5;
    box-sizing: border-box;
    /*display:none;*/
    z-index:2;
}

@media only screen and (min-width:991px){
    .top-nav-container {
        display:block;
        width:970px;
        margin:-17px auto 0;
    }
}

@media only screen and (min-width:1200px){
    .top-nav-container {
        width:1170px;
        margin:-17px auto 0;
    }  
}
