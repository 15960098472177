/*
.support-desk {
    width:260px;
    border:1px solid black;
    position:fixed;
    right:-260px;
    top:225px;
    color:#444;
    font-size:14px;
    border-left:3px solid #007db8;
    border-top:1px solid #aaa;
    border-bottom:1px solid #aaa;
    border-right:1px solid #aaa;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,.3);
    box-sizing: border-box;
    transition:right .5s ease;
    z-index: 6;
}

.support-desk {
    width:260px;
    border:1px solid black;
    position:fixed;
    left:0px;
    bottom:-258px;
    color:#444;
    font-size:14px;
    border-left:1px solid #aaa;
    border-top:3px solid #007db8;
    border-bottom:1px solid #aaa;
    border-right:1px solid #aaa;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,.3);
    box-sizing: border-box;
    transition:right .5s ease;
    z-index: 6;
}
*/

/*
.support-desk-handle-link {
    height:28px;
    box-sizing: border-box;
    top:55px;
    left:-87px;
    position: absolute;
    background-color: #007db8;
    padding:0 15px;
    text-decoration: none;
    transform:rotate(-90deg);
    box-shadow: -4px -2px 6px 0px rgba(0,0,0,.3);
}
*/

/*
.support-desk-handle-text {
    display:flex;
    align-items: center;
    color:white;
    font-size: 14px;
    line-height:28px;
    box-sizing: border-box;
}
*/

.support-desk-content {
    background-color:#fff;
    padding:11px 37px 10px 23px;
    height:190px;
    box-sizing: border-box;
}

.support-desk-close-btn {
    /*height:34px;
    width:34px;*/
    padding-right:3px;
    background-color: transparent;
    border-style:none;
    outline-style: none;
    position: absolute;
    top:1px;
    right:1px;
    /*font-size:27px;*/
    color:#000;
    font-weight: 500;
    cursor: pointer;
}

.support-desk-close-x {
    font-size: 27px;
    width:34px;
    height:34px;
    display: inline-block;
}

/*
.close-support-desk {
    right:-260px;

}

.open-support-desk {
    right:0px;
}
*/

.support-desk-content-message {
    margin:10px 0;
    color:#444444;
    box-sizing: border-box;
}

.support-desk-content-list {
    padding:0;
    margin:14px 0;
    list-style-type: none;
    box-sizing: border-box;
}

.support-desk-content-list > li:nth-child(2) {
    margin:22px 0 15px;
    box-sizing: border-box;
}

.support-desk-content-list > li > a {
    text-decoration: none;
    box-sizing: border-box;
    /*border:1px solid red;*/
    line-height:20px;
    display:table;
}

.support-desk-content-list-item-text {
    padding-left:8px;
    color:#007db8;
    /*border:1px solid black;*/
    box-sizing: border-box;
    display:table-cell;
    vertical-align: middle;
}

.support-desk-icon-container {
    display:table-cell;
    text-align: center;
    width:20px;
    box-sizing: border-box;
}

.support-desk-handle-link {
    display:none;
}

.support-desk-handle-link-mobile {
    height:28px;
    box-sizing: border-box;
    left:10px;
    bottom:40px;
    height:40px;
    width:40px;
    position: fixed;
    display:block;
    background-color: #007db8;
    padding:0;
    text-decoration: none;
    /*z-index: -1;*/
    z-index: 1;
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,.3);
    /*text-align: center;*/
    padding:6px 9px;
}

.sd-container {
    position:fixed;
    width:40px;
    height:40px;
    z-index:1;
    left:10px;
    bottom:40px;
}

/*
.support-desk-icon {
    color:#007db8;
    box-sizing: border-box;
    font-size:20px;
}
*/

@media only screen and (min-width:992px){
    .support-desk {
        width:260px;
        border:1px solid black;
        position:fixed;
        right:-260px;
        top:225px;
        color:#444;
        font-size:14px;
        border-left:3px solid #007db8;
        border-top:1px solid #aaa;
        border-bottom:1px solid #aaa;
        border-right:1px solid #aaa;
        box-shadow: 2px 2px 5px 0 rgba(0,0,0,.3);
        box-sizing: border-box;
        transition:right .5s ease;
        z-index: 6;
    }

    .close-support-desk-right {
        right:-260px;
    
    }
    
    .open-support-desk-right {
        right:0px;
    }

    .support-desk-handle-link {
        height:28px;
        box-sizing: border-box;
        top:36px;
        left:-67px;
        position: absolute;
        display:block;
        background-color: #007db8;
        padding:0 15px;
        text-decoration: none;
        transform:rotate(-90deg);
        box-shadow: -4px -2px 6px 0px rgba(0,0,0,.3);
    }

    .support-desk-handle-link-mobile {
        display:none;
    }

    /*
    .support-desk-handle-text {
        display:flex;
        align-items: center;
        color:white;
        font-size: 14px;
        line-height:28px;
        box-sizing: border-box;
    }
    */

    .support-desk-handle-text {
        display:flex;
        align-items: center;
        color:white;
        font-size: 14px;
        line-height:28px;
        box-sizing: border-box;
    }

    .support-desk-icon {
        display:none;
    }

    .support-desk-list-icon {
        color:#007db8;
        box-sizing: border-box;
        font-size:20px;
    }
}


@media only screen and (max-width:991px){
    .support-desk {
        width:260px;
        border:1px solid black;
        position:fixed;
        left:0px;
        bottom:-258px;
        color:#444;
        font-size:14px;
        border-left:1px solid #aaa;
        border-top:3px solid #007db8;
        border-bottom:1px solid #aaa;
        border-right:1px solid #aaa;
        box-shadow: 2px 2px 5px 0 rgba(0,0,0,.3);
        box-sizing: border-box;
        transition:bottom .5s ease;
        z-index: 6;
    }

    .close-support-desk-bottom {
        bottom:-260px;
    
    }
    
    .open-support-desk-bottom {
        bottom:0px;
    }

    .support-desk-handle-text {
        display:none;
    }

    .support-desk-icon {
        color:#fff;
        box-sizing: border-box;
        font-size:22px;
        position:relative;
        top:2.2px;
    }

    .support-desk-list-icon {
        color:#007db8;
        box-sizing: border-box;
        font-size:20px;
    }
}
