.left-col {
    box-sizing: border-box;
    margin: 0 30px 0 0;
    flex-grow: 1;
}

.search-container {
    padding:19px 0 0;
    box-sizing: border-box;
}

.search-box-form {
    display:flex;
    /*border:1px solid black;*/
    height:28px;
    box-sizing: border-box;
}

.search-text-desktop {
    font-size: 14px;
    font-family: Roboto;
}

.search-icon-container-mobile {
    display: none;
}

.form-search-box {
    flex-grow:1;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border:1px solid #444444;
    padding:5px 80px 5px 5px;
}

.form-search-box-btn {
    background-color: #444444;
    color:white;
    border-style:none;
    padding:2px 12px;
    cursor: pointer;
}

@media only screen and (max-width:768px){
    .search-container {
        display:none;
    }

    .search-container-mobile {
        display: block;
        position: absolute;
        top:100%;
        left:0;
        right:0;
        padding:10px 15px;
        background-color: #fff;
    }

    .form-search-box {
        height:30px;
        font-size: 16px;
    }

    .form-search-box-btn {
        background-color: #444444;
        color:white;
        border-style:none;
        height:30px;
    }

    .search-text-desktop {
        display:none;
    }

    .search-icon-container-mobile {
        display:block;
        font-size: 14px;
    }

}

@media only screen and (min-width:1400px){
    .search-container {
        max-width: 600px;
    }
}