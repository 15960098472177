.right-col {
    box-sizing: border-box;
    display:flex;
}

.sign-in-icon, .shopping-cart-icon {
    font-size: 22px;
}

.sign-in-icon-container {
    margin:0 5px 0 0;
}

.sign-in-btn {
    background-color: transparent;
    border-style: none;
    padding:0;
    display:flex;
    align-items: center;
    color:#444;
    cursor: pointer;
}

.shopping-cart-icon-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color:#444;
    cursor: pointer;
}

.sign-in-text, .shopping-cart-text, .country-text {
    font-size: 14px;
}

.shopping-cart-icon-container {
    margin:0 5px 0 0;
}

.right-btn-option:nth-child(1){
    display:none;
}

.right-btn-option:nth-child(2), .right-btn-option:nth-child(3){
    padding:0 10px;
    box-sizing: border-box;
    display:flex;
}

.right-btn-option:nth-child(4){
    padding:25px 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    /*border:1px solid green;*/
    position: relative;
    display: none;
}

.right-btn-menu-option {
    height:100%;
    /*border:1px solid black;*/
    box-sizing: border-box;
}

.menu-btn-container {
    height:100%;
    box-sizing: border-box;
}

.menu-btn{
    border-style: none;
    outline-style: none;
    background-color: transparent;
    box-sizing: border-box;
    padding:10px 15px;
    height:100%;
    cursor: pointer;
    display:flex;
    align-items: center;
    color:#444444;
}

.search-btn {
    color:#444444;
}

.menu-btn-icon-container {
    margin-right:5px;
    box-sizing: border-box;
}

.menu-btn-icon {
    font-size:20px;
}

.menu-btn-text {
    font-size:14px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

.country-list {
    padding:0;
    margin:0;
    height:250px;
    width:268px;
    list-style-type: none;
    overflow-y: scroll;
    position: absolute;
    right:10px;
    top:50px;
    background-color: #fff;
    border-left:1px solid rgb(0, 0, 0, .15);
    border-bottom:1px solid rgb(0, 0, 0, .15);
    box-shadow: 0px 6px 12px 0px rgb(0, 0, 0, .175);
    box-sizing: border-box;
}

.country-list-item-link {
    display:block;
    padding:3px 10px;
    color:#444;
    text-decoration: none;
    font-size: 14px;
    box-sizing: border-box;
}

.country-list-item-link:hover {
    background-color:#cce7f3;
}

@media only screen and (max-width:768px){
    .right-btn-option:nth-child(1){
        display:block;
    }

    .right-btn-option {
        min-width:46px;
    }

    .search-btn {
        border:none;
        background-color: transparent;
    }

    .search-btn, .sign-in-btn {
        display:flex;
        height:100%;
        width:100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /*border:1px solid red;*/
        padding:10px 0;
        font-size: 12px;
        font-family: roboto ,Arial, Helvetica, sans-serif;
    }

    .search-icon-container, .sign-in-icon-container, .shopping-cart-icon-container {
        /*border:1px solid green;*/
        margin:0;
    }

    .shopping-cart-icon-link {
        flex-direction: column;
        /*border:1px solid red;*/
        padding:10px 0;
        font-size: 12px;
        width:100%;
        font-family: roboto ,Arial, Helvetica, sans-serif;
    }

    .menu-btn {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding:14px 14px 10px 5px;
        width:100%;
    }

    .menu-btn-icon-container {
        /*border:1px solid black;*/
        width:100%;
        margin:0;
    }

    .search-text, .sign-in-text, .shopping-cart-text, .menu-btn-text {
        font-size: 12px;
    }

    .shopping-cart-text {
        padding:10px 0 0;
    }

    .right-btn-option:nth-child(1),
    .right-btn-option:nth-child(2), 
    .right-btn-option:nth-child(3) {
        padding:0 5px;
    }
}

@media only screen and (min-width:991px){
    .right-btn-option:nth-child(4) {
        display: block;
    }

    .right-btn-menu-option {
        display:none;
    }
}