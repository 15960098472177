.detail-nav-icon-container {
    text-align: center;
}

.detail-nav-icon {
    font-size: 43px;
}

.detail-nav-tagline {
    margin:10px 0;
    text-align: center;
    font-size: 29px;
    color:#444;
}

.detail-nav-text {
    margin:0 0 5px;
    font-size:17px;
    text-align: center;
}

.detail-nav-link-container {
    margin:20px 0!important;
    padding:5px 0 0!important;
    text-align: center;
}

@media only screen and (max-width:3020px) and (min-width:1441px){
    .detail-nav-tagline {
        font-size: 34px;
    }

    .detail-nav-text {
        font-size: 22px;
    }

    .detail-nav-container .support-section-item-link {
        font-size: 22px;
    }

}

@media only screen and (max-width:1440px) and (min-width:1281px){
    .detail-nav-tagline {
        font-size: 31px;
    }

    .detail-nav-text {
        font-size: 18px;
    }

    .detail-nav-container .support-section-item-link {
        font-size: 18px;
    }

}

@media only screen and (max-width:991px){
    .detail-nav-tagline {
        font-size: 22px;
    }

}

@media only screen and (max-width:768px){
    .detail-nav-tagline {
        font-size: 20px;
    }

    .support-section-item-link {
        font-size: 16px!important;
    }

    .support-section-item {
        margin-top:5px;
        
    }

}