.middle-container {
    position:fixed;
    box-sizing: border-box;
    top:0;
    left:0;
    right:0;
    width:100vw;
    z-index: 0;
}

.bf-container-wrap {
    position: absolute;
    display:flex;
    box-sizing: border-box;
    align-items: center;
    /*border:1px solid green;*/
    z-index: 1;
    width: 100%;
    height:calc(100% - 79px);
    top:79px;
    bottom:0;
    left:0;
}


.bf-container {
    /*border:1px solid green;*/
    text-align: center;
    box-sizing: border-box;
    width:50%;
    /*color:#fff;*/
}

.bf-title {
    font-size: 15px;
    margin:0 0 10px;
    color:#444444;
}
.bf-headline {
    font-size:29px;
    color:#444444;
}

.bf-promo-text {
    font-size:17px;
    margin:10px 38px 0;
    color:#444444;
}

.bf-btn-container {
    margin:20px 0 0;
    box-sizing: border-box;
}

.bf-btn {
    margin:0 5px;
    padding:5px 15px;
    background-color: transparent;
    border:1px solid #444;
    color:#444;
    /*color:#fff;
    border:1px solid #fff;*/
    font-size: 17px;
    cursor: pointer;
}

.bf-btn:hover {
    background-color: #444;
    color:#fff;
}

@media only screen and (max-width:900px) and (min-width:769px){
    .bf-container-wrap {
        top:66px;
    }

    .bf-container {
        padding:0 16px;
    }
}

@media only screen and (max-width:768px){
    .middle-container {
        top:0;
        right:0;
        left:0;
        /*height:calc(var(--vh, 1vh) * 100);*/
        width:100vw;
        position: relative;
        z-index: 0;
        overflow: hidden;
        /*margin-bottom: 0;
        padding-top:0;*/
    }

    .promotion-image {
        height:calc((var(--vh, 1vh) * 100) - 66px);
        width:100vw;
        margin:0 auto;
        left:0;
        right:0;
        z-index:0;
        object-fit: cover;
    }

    .bf-container-wrap {
        position:absolute;
        top:0;
        left:0;
        right:0;
        height:calc((var(--vh, 1vh) * 100) - 66px - 60px);
        /*border:1px solid black;*/
        display:block;
    }

    .bf-container {
        width:100%;
        position:relative;
        top:50%;
        height:50%;
        /*border:1px solid green;*/
        display:flex;
        justify-content: center;
        flex-direction: column;
    }

    .bf-title {
        font-size: 15px;
        margin:0 0 10px;
        color:#444444;
    }
    .bf-headline {
        font-size:22px;
        color:#444444;
    }
    
    .bf-promo-text {
        font-size:16px;
        margin:10px 38px 0;
        color:#444444;
    }

    .bf-btn {
        font-size: 16px;
    }
}

@media only screen and (max-width:414px){
    .bf-title {
        font-size: 14px;
        margin:0 0 10px;
        color:#444444;
    }
    .bf-headline {
        font-size:20px;
        color:#444444;
    }
    
    .bf-promo-text {
        font-size:16px;
        margin:10px 38px 0;
        color:#444444;
    }

    .bf-btn {
        font-size: 16px;
    }

}

@media only screen and (max-width:1440px) and (min-width:1281px){
    .bf-title {
        font-size: 15px;
        margin:0 0 10px;
        color:#444444;
    }
    .bf-headline {
        font-size:31px;
        color:#444444;
    }
    
    .bf-promo-text {
        font-size:18px;
        margin:10px 38px 0;
        color:#444444;
    }

    .bf-btn {
        font-size: 18px;
    }

    .bf-text-wrap {
        margin:0 25%;
    }
}

@media only screen and (min-width:1441px){
    .bf-title {
        font-size: 16px;
        margin:0 0 10px;
        color:#444444;
    }
    .bf-headline {
        font-size:34px;
        color:#444444;
    }
    
    .bf-promo-text {
        font-size:22px;
        margin:10px 38px 0;
        color:#444444;
    }

    .bf-btn {
        font-size: 22px;
    }

    .bf-text-wrap {
        margin:0 25%;
    }
}

