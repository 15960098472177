* {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

body{
  transition: right .2s ease-in;
  position: relative;
  right:0;
}

/**move site to left for mobile menu**/
.move-app-left {
  right:276px;
  position: fixed;
  width:100%;
  overflow:hidden;
}

.spacer {
  margin-top:-79px;
  /*border: 1px solid red;*/
  /*position: relative;*/
  padding-top:39.5%;
  width:100%;
}

@media only screen and (max-width:768px){
  .spacer {
    display:none;
  }

}

@media only screen and (max-width:990px) and (min-width:769px){
  .spacer {
    margin-top:-66px;
  }
}
