.product-category-section {
    /*border-top:1px solid orange;*/
    display:flex;
    padding:0;
    margin:0;
    list-style-type: none;
    height:130px;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: #f9f9f9;
    border-bottom:1px solid #ccc;
}

.sticky {
    position:sticky;
    left:0;
    right:0;
    top:0;
    box-shadow: 0 0 7px rgba(0,0,0,.3);
    z-index:5;
}

.product-category {
    height:100%;
    width:170px;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    /*touch-action: none;*/
}

.product-category-heading {
    width:100%;
    height:205px;
    position:absolute;
    top:0;
    left:0;
    box-sizing: border-box;
    text-align: center;
    transform: translateY(0);
    transition:transform .4s ease;
}

/*
.product-category:hover {
    border-left:1px solid #ccc;
    border-right:1px solid #ccc;
}
*/

.side-borders {
    border-left:1px solid #ccc;
    border-right:1px solid #ccc;
}


/*
.product-category:hover .product-category-heading {
    transform:translateY(-75px);
    background-color:#ffffff;
}
*/

.move-up-heading{
    transform:translateY(-75px);
    background-color:#ffffff;  
}



.category-icon-container {
    margin:41px 0 0 0;
}

.category-icon {
    max-width: 30px;
}

.product-category-heading-caption {
    margin:14px 0 20px;
    text-align: center;
    font-size: 16px;
    color:black;
    transition: text-shadow .3s;
}

/*
.product-category:hover .product-category-heading-caption {
    text-shadow: 0 .9px black;
}
*/

.dark-shadow{
    text-shadow: 0 .9px black;
}

.product-category-heading-item-link{
    text-decoration: none;
}

.product-category-heading-item {
    margin-bottom:20px;
    color:#006bbd;
    /*font-size: 16px;*/
}

.product-category-heading-link {
    text-decoration: none;
    color:#444;
}

.product-category-heading-no-slide {
    width:100%;
    height:205px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.product-category-heading-caption-no-slide {
    margin:14px 0 20px;
    text-align: center;
    /*font-size: 16px;*/
    box-sizing: border-box;
    /*transition: text-shadow .1s;*/
}

/*
.product-category:hover .product-category-heading-no-slide {
    background-color:#ffffff;
}
*/

.light-bg-color {
    background-color:#ffffff;
}

/*
.product-category:hover .product-category-heading-caption-no-slide {
    color:#006bbd;
    font-size: 16px;
    text-shadow: 0 0 .9px #006bbd;
}
*/

.blue-shadow {
    color:#006bbd;
    font-size: 16px;
    text-shadow: 0 0 .9px #006bbd;
}

.category-icon-container-no-slide {
    /*margin:40px 0 0 0;*/
    padding:40px 0 0;
    box-sizing: border-box;
}

@media only screen and (max-width:768px){

    .sticky {
        position: relative;
    }

    .product-category-section {
        height:auto;
        flex-wrap: wrap;
    }

    .product-category {
        width:50vw;
        height:130px;
    }
}