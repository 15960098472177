.product-search-options-container {
    height:66px;
    box-sizing: border-box;
    display: flex;
    /*margin:0 39px;*/
    /*margin:0 auto;*/
    padding:0 20px;
    /*border:1px solid green;*/
}

@media only screen and (max-width:990px){
    .product-search-options-container {
        padding: 0 0 0 15px;
    }   
}

@media only screen and (min-width:991px){
    .product-search-options-container {
        width:970px;
        margin:0 auto;
    }   
}

@media only screen and (min-width:1200px){
    .product-search-options-container {
        width:1170px;
        margin:0 auto;
    }   
}