.support-section {
    background-color:#f9f9f9;
    padding:40px 4vw;
    text-align: center;
    box-sizing: border-box;
    z-index: 3;
    /*border:1px solid green;*/
}

.dell {
    display: none;
}

.support-section-title {
    font-size:31px;
    line-height:25px;
    margin:0 0 10px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
}

.support-section-list {
    /*height:21px;*/
    padding:0;
    margin:0;
    box-sizing: border-box;
}

.support-section-item {
    border-right:1px solid #707070;
    display:inline-block;
    padding:0 20px;
    box-sizing: border-box;
}

.support-section-item:last-child {
    border:none;
}

.support-section-item-link {
    font-size:17px;
    text-decoration: none;
    color:#006bdd;
    box-sizing: border-box;
}

@media only screen and (min-width:769px){
    .dell {
        display:block;
    }
}

@media only screen and (min-width:992px){
    .dell .support-section-title {
        font-size: 29px;
    }
    .dell .support-section-item-link {
        font-size: 17px;
    }
}

@media only screen and (max-width:1440px) and (min-width:1281px){
    .dell .support-section-title {
        font-size: 31px;
    }
    .dell .support-section-item-link {
        font-size: 18px;
    }
}

@media only screen and (max-width:991px){
    .dell .support-section-title {
        font-size: 22px;
    }
    .dell .support-section-item-link {
        font-size: 16px;
    }
}