.product-section {
    /*border:1px solid violet;*/
    display:flex;
    box-sizing: border-box;
    overflow: visible;
    /*min-height:19rem;*//*25rem for 1010px and higher*/
    z-index: 4;
}

.product-section-reverse {
    /*border:1px solid orange;*/
    display:flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    overflow: visible;
    z-index: 4;
}

.hide{
    display: none;
}

.product-image-container {
    /*border:1px solid red;*/
    /*flex-basis:72%;*/
    box-sizing: border-box;
    /*top:90px;*/
    transform:translate3d(0, 100px, 0);
    height:auto;
    /*position: relative;*/
    opacity:0;
    /*opacity:1;*/
    will-change: transform, opacity;
    transition:transform 1s ease-in-out, opacity 1s ease-in-out;
    /*overflow: hidden;*/
    flex-basis: 55%;
}

.product-image-container-link {
    text-decoration: none;
    color:#444;
    box-sizing: border-box;
    height:100%;
    display:block;
    /*border:1px solid blue;*/
}

.product-image {
    height:100%;
    width:100%;
    /*border:1px solid green;*/
    display:block;
    box-sizing: border-box;
}

.product-content-container {
    /*border:1px solid orange;*/
    /*padding:0 3.75rem;*/
    padding:0 5%;
    display:flex;
    align-items: center;
    flex-basis:45%;
    box-sizing: border-box;
    transform: translate3d(0,100px,0);
    /*left:0;
    position: relative;*/
    will-change: transform, opacity;
    opacity:0;
    transition:transform 1s ease-in-out, opacity 1s ease-in-out;
}

/**move product image and product content container up**/
.move-container-up{
    transform:translate3d(0,0,0);
    opacity:1;
}


.product-content-title-link {
    text-decoration: none;
    color:#444;
    box-sizing: border-box;
    
}

.product-content-title {
    color:#707070;
    font-size: 15px;
    margin:0 0 10px;
    text-transform: uppercase;
    box-sizing: border-box;
}

.product-content-tagline {
    font-size: 29px;
    color:#444444;
    margin:10px 0;
    box-sizing: border-box;
}

.product-content-text {
    margin:0 0 5px;
    line-height:150%;
    font-size:17px;
    box-sizing: border-box;
}

.product-type-container {
    margin:20px 0;
    box-sizing: border-box;
}

.product-type-link-left {
    text-decoration: none;
    color:#0076ce;
    font-size:17px;
    padding:0 20px 0 0;
    border-right:1px solid grey;
    box-sizing: border-box;
    display: inline-block;
    /*border:1px solid red;*/
}

.product-type-link-right {
    text-decoration: none;
    color:#0076ce;
    font-size:17px;
    padding:0 0 0 20px;
    display: inline-block;
    box-sizing: border-box;
    left:5px;
    position:relative;
    /*border:1px solid black;*/
}

.product-type-link-single {
    text-decoration: none;
    color:#0076ce;
    font-size:17px; 
    box-sizing: border-box;
}

@media only screen and (max-width:3020px) and (min-width:1441px){
    .product-content-tagline{
        font-size: 34px;
    }

    .product-content-text, .product-type-link-left, .product-type-link-right {
        font-size:22px;
    } 
}

@media only screen and (max-width:1440px) and (min-width:1281px){
    .product-content-tagline{
        font-size: 31px;
    }

    .product-content-text, .product-type-link-left, .product-type-link-right {
        font-size:18px;
    } 
}


@media only screen and (max-width:991px) {
    .product-content-tagline{
        font-size: 22px;
    }

    .product-content-text, .product-type-link-left, .product-type-link-right {
        font-size:16px;
    }

}

@media only screen and (max-width:768px) {
    .product-section, .product-section-reverse{
        flex-direction: column;
    }

    .product-content-title {
        font-size: 14px;
    }

    .product-content-tagline {
        font-size: 20px;
    }

    .product-content-text {
        font-size: 16px;
    }

    .product-image-container, .product-content-container {
        opacity:1;
        transform:translateX(0) translateY(0)!important;
    }

}
